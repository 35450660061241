import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

const AUTH_TOKEN = 'ZGFwLnNlcnZpY2U6RGFwQDEyMw==';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const customHeaders = {
      Authorization: `Basic ${AUTH_TOKEN}`,
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS'
    };

    const authenticatedUser = this.authService.getAuthenticatedUser;
    if (authenticatedUser && authenticatedUser.sessionId) {
      Object.assign(customHeaders, {
        'Session-Token': authenticatedUser.sessionId
      });
    }

    request = request.clone({ setHeaders: customHeaders });
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authService.logout();
        location.reload(true);
      }
      
      const error = err.message || err.statusText;
      return throwError(error);
    }));
  }
}
