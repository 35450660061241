import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { ApplicationsService } from 'src/app/services/applications.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessagesService } from 'src/app/services/messages.service';
// import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass']
})
export class LayoutComponent implements OnInit, OnDestroy {
  currentYear;
  authenticatedUser: User;

  messageCount = 0;
  countSubscription: Subscription = new Subscription();

  constructor(
    // private _router: Router,
    private authService: AuthService,
    private messagesService: MessagesService
  ) {
    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  ngOnInit(): void {
    this.authenticatedUser = this.authService.getAuthenticatedUser;
    if (this.authenticatedUser) {
      this.messagesService.updateMessageCount();
      this.countSubscription.add(
        this.messagesService.messageCount
          .subscribe((value) => this.messageCount = value)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.countSubscription) {
      this.countSubscription.unsubscribe();
    }
  }

  logout() {
    this.authService.logout();
    // this._router.navigate(['/autentifikimi/identifikim']);
  }
}
