import { Injectable } from '@angular/core';
import { User, Response } from '../models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticatedUser: User;

  constructor(
    private http: HttpClient
  ) { }

  get getAuthenticatedUser(): User {
    if (this.authenticatedUser) {
      return this.authenticatedUser;
    }

    this.authenticatedUser = JSON.parse(localStorage.getItem('authenticated-user'));
    return this.authenticatedUser;
  }

  login(credentials): Promise<Response> {
    return this.http.post<Response>(`${environment.apiURL}/post/login`, credentials)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        this.authenticatedUser = response.data;
        localStorage.setItem('authenticated-user', JSON.stringify(this.authenticatedUser));
        return response;
      });
  }

  register(credentials): Promise<Response> {
    return this.http.post<Response>(`${environment.apiURL}/post/register`, credentials)
      .pipe(take(1))
      .toPromise();
  }

  requestAccountVerification(guid: string): Promise<Response> {
    const payload = { guid };
    return this.http.post<Response>(`${environment.apiURL}/post/activate`, payload)
     .pipe(take(1))
     .toPromise();
  }

  logout() {
    localStorage.removeItem('authenticated-user');
    localStorage.clear();
    this.authenticatedUser = undefined;
    location.reload(true);
  }

  forgotPassword(email) {
    const payload = { email };
    return this.http.post(`${environment.apiURL}/post/forgotpassword`, payload)
      .pipe(take(1))
      .toPromise();
  }

  verifyPasswordResetToken(token) {
    const payload = { token };
    return this.http.post(`${environment.apiURL}/post/checktoken`, payload)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => response.data)
      .catch(error => error);
  }

  resetPassword(payload) {
    return this.http.post(`${environment.apiURL}/post/resetpassword`, payload)
      .pipe(take(1))
      .toPromise();
  }
}
