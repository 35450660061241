import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Response } from '../models/response'
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  messageCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient
  ) { }

  getMessages(payload: any): Promise<any> {
    return this.http.post(`${environment.apiURL}/post/messages`, payload)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        return response.data;
      });
  }

  markAsRead(messageId: string): Promise<any> {
    return this.http.get(`${environment.apiURL}/get/readMessage/${messageId}`)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        return response;
      });
  }

  updateMessageCount(): void {
    this.http.get(`${environment.apiURL}/get/messageCount`)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        if (response && response.data) {
          this.messageCount.next(response.data.messageNo);
        }
      })
      .catch((error) => console.error(error));
  }
}
