<div fxLayout="column" fxLayoutGap="1rem">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" class="pb-1">
    <h1>Konfirmimi i pjesëmarrjes në testim</h1>
    <div *ngIf="isLoading || isWaitingResponse" class="spinner-container">
      <mat-progress-spinner diameter="28" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <mat-card class="overflow-auto">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="isAccessible && booking; else notAccessible">
        <h3 class="mb-1">Vëmendje!</h3>
        <p>
          Ju jeni kualifikuar për të marrë pjesë në fazën e testimit me shkrim për pozicionin/pozicionet e shpalljes
          <b>{{ booking.publicationName }}</b>.
          Testimi me shkrim do të zhvillohet më datë
          <b>{{ booking.testingDate }}</b>
          duke filluar nga ora <b>{{ booking.startTime }}</b>
          deri në orën <b>{{ booking.endTime }}</b>
          në adresën <b>{{ booking.address }}</b>.
        </p>
        <p class="mb-15">Jeni të lutur të paraqiteni të pajisur me një dokument identiteti.</p>
        <button mat-raised-button
          color="primary"
          (click)="openConfirmationModal()"
          [disabled]="booking.hasConfirm || isWaitingResponse">
          Konfirmo pjëmarrjen
        </button>
      </ng-container>
      <ng-template #notAccessible>
        <h2>Faqja nuk është e disponueshme</h2>
      </ng-template>
    </ng-container>
  </mat-card>
</div>