import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// components
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { PositionSelectionComponent } from 'src/app/shared/components/position-selection/position-selection.component'

// guards
import { AuthGuard } from 'src/app/guards/auth.guard';
import { BookingComponent } from './shared/components/booking/booking.component';

const routes: Routes = [
  { path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'shpalljet'
      },
      {
        path: 'shpalljet',
        loadChildren: () => import('./modules/publications/publications.module')
          .then(m => m.PublicationsModule)
      },
      {
        path: 'perdoruesi',
        loadChildren: () => import('./modules/user/user.module')
          .then(m => m.UserModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mesazhet',
        loadChildren: () => import('./modules/messages/messages.module')
          .then(m => m.MessagesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'ankimimi',
        loadChildren: () => import('./modules/complains/complains.module')
          .then(m => m.ComplainsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'testimet',
        loadChildren: () => import('./modules/testing/testing.module')
          .then(m => m.TestingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'perzgjidh-pozicion',
        component: PositionSelectionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'konfirmo-pjesemarrjen',
        component: BookingComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'autentifikimi',
    loadChildren: () => import('./modules/auth/auth.module')
      .then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  { path: 'nuk-u-gjet', component: NotFoundComponent },
  { path: '**', redirectTo: 'nuk-u-gjet' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
