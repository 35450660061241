<div fxLayout="column" fxLayoutGap="1rem">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" class="pb-1">
    <h1>Përzgjedhja e pozicionit</h1>
    <div *ngIf="isLoading || isWaitingResponse" class="spinner-container">
      <mat-progress-spinner diameter="28" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <mat-card class="overflow-auto">
    <ng-container *ngIf="!isLoading">
      <ng-container *ngIf="isAccessible; else notAccessible">
        <h3>Përshëndetje {{ authenticatedUser.applicantName }} {{ authenticatedUser.applicantSurname }}!</h3>
        <p>Ju jeni shpallur fitues në shpalljen <b>{{ publicationName }}</b></p>
        <p>
          Departamenti i Administratës Publike ju informon se ju duhet të zgjidhni pozicionin nga grupi i pozicioneve të mbetura.
          Afati i përzgjedhjes është deri më {{ deadline }}.
        </p>
        <p class="mb-15">
          <b>Nëse ju nuk bëni zgjedhjen brenda këtij afati, procesi vazhdon me kandidatin e rradhës fitues sipas listës së renditur të kandidatëve fitues.</b>
          <br>(shënim: Rradha e kandidatëve fitues me pikë të njëjta zgjidhet random nga sistemi)
        </p>

        <ng-container *ngIf="selectedPositions?.length">
          <table mat-table [dataSource]="selectedPositions" class="w-100 mb-2">
            <ng-container matColumnDef="nr">
              <th mat-header-cell *matHeaderCellDef>Nr.</th>
              <td mat-cell *matCellDef="let position">{{ position.nr }}</td>
            </ng-container>
  
            <ng-container matColumnDef="positionSelected">
              <th mat-header-cell *matHeaderCellDef>Pozicioni</th>
              <td mat-cell *matCellDef="let position">{{ position.positionSelected }}</td>
            </ng-container>
  
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Statusi</th>
              <td mat-cell *matCellDef="let position">{{ position.status }}</td>
            </ng-container>
  
            <ng-container matColumnDef="applicant">
              <th mat-header-cell *matHeaderCellDef>Aplikanti</th>
              <td mat-cell *matCellDef="let position">{{ position.applicant }}</td>
            </ng-container>
  
            <ng-container matColumnDef="selectionDate">
              <th mat-header-cell *matHeaderCellDef>Data e përzgjedhjes</th>
              <td mat-cell *matCellDef="let position">{{ position.selectionDate }}</td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </ng-container>

        <h3 class="mb-1">Lutem zgjidhni pozicionin sipas listës më poshtë:</h3>

        <mat-radio-group (change)="selectedPositionId = $event.value" color="primary" fxLayout="column">
          <mat-radio-button *ngFor="let position of availablePositions" [value]="position.positionId" class="mb-05">
            {{ position.positionName }}
          </mat-radio-button>
        </mat-radio-group>

        <div fxLayout="row" fxLayoutAlign="end center" class="mt-15">
          <button mat-raised-button
            color="primary"
            (click)="openConfirmationModal()"
            [disabled]="!selectedPositionId || completed || isWaitingResponse">
            Përzgjidh pozicion
          </button>
        </div>
      </ng-container>
      <ng-template #notAccessible>
        <h2>Faqja nuk është e disponueshme</h2>
      </ng-template>
    </ng-container>
  </mat-card>
</div>