<div fxLayout="column" fxLayoutAlign="center center" class="h-100">
  <div class="message-container">
    <h2>Faqja nuk u gjet</h2>
    <p>
      Ju kërkojmë ndjesë, faqja që ju po kërkoni nuk ekziston.
      <strong>
        <a [routerLink]="['/']">Kthehu tek faqja kryesore.</a>
      </strong>
    </p>
  </div>
</div>