import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private _router: Router
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      const hasUser = Boolean(this.authService.getAuthenticatedUser);
      let canAccess = hasUser;

      if (state.url.includes('autentifikimi')) {
        canAccess = true;

        if (hasUser) this.authService.logout();

        if (state.url.includes('rivendos-fjalekalimin')) {
          const resetToken = state.url.split('/')[3];
          const verification = await this.authService.verifyPasswordResetToken(resetToken);
          canAccess = Boolean(verification === true);
        }
      }

      if (!canAccess) this._router.navigate(['/']);

      return canAccess;
  }
}
