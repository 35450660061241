import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Response } from '../models/response';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PositionSelectionService {

  constructor(
    private http: HttpClient
  ) { }

  getPostions(id): Promise<any> {
    return this.http.get(`${environment.apiURL}/get/selectPosition/${id}`)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        return response.data;
      });
  }

  selectPostion(payload): Promise<any> {
    return this.http.post(`${environment.apiURL}/post/registerSelection`, payload)
      .pipe(take(1))
      .toPromise();
  }
}
