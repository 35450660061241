import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { Booking, Response } from 'src/app/models';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.sass']
})
export class BookingComponent implements OnInit, OnDestroy {
  routeSubscription: Subscription;
  queryParams: any;

  booking: Booking;

  isLoading = false;
  isAccessible = false;
  isWaitingResponse = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private bookingService: BookingService
  ) { }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.routeSubscription = this._route.queryParams.subscribe((params) => {
      this.queryParams = params;

      if (params.PublicationID && params.ProcedureID) {
        this.loadBookingInformation();
      }
    });
  }

  loadBookingInformation() {
    this.isLoading = true;

    const payload = {
      publicationId: this.queryParams.PublicationID,
      procedureId: this.queryParams.ProcedureID
    };

    this.bookingService.getBookinfInformation(payload)
      .then((booking: Booking) => {
        this.booking = booking;
        this.isAccessible = true;
      })
      .finally(() => this.isLoading = false)
      .catch((error) => console.error(error));
  }

  openConfirmationModal() {
    const dialogRef = this._dialog.open(ConfirmationModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isWaitingResponse = true;

        this.bookingService.confirmBooking({bookingClassRoomId: this.booking.bookingClassRoomId})
          .then((response: Response) => {
            if (response.code === 200) {
              this._snackBar.open('Konfirmimi u krye me sukses', null, { duration: 3000 });
              setTimeout(() => {
                this._router.navigate(['/']);
              }, 3000);
            } else {
              this._snackBar.open('Lutemi të rikonfirmoni', null, { duration: 3000 });
              this.loadBookingInformation();
            }
          })
          .finally(() => this.isWaitingResponse = false)
          .catch((error) => {
            console.error(error);
            this._snackBar.open('Ndodhi një gabim', null, { duration: 3000 });
          });
      }
    });
  }
}
