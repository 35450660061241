import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Response, Booking } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private http: HttpClient
  ) { }

  getBookinfInformation(
    payload: { publicationId: number, procedureId: number }
  ): Promise<Booking> {
    return this.http.post(`${environment.apiURL}/post/bookingInfo`, payload)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        return response.data as Booking;
      });
  }

  confirmBooking(payload: {bookingClassRoomId: number}): Promise<any> {
    return this.http.post(`${environment.apiURL}/post/book`, payload)
      .pipe(take(1))
      .toPromise()
      .then((response: Response) => {
        return response;
      });
  }
}
