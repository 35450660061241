<section fxLayout="column" class="main-container">
  <header fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center">
    <a href="">
      <img src="assets/logo_republika.png" alt="">
    </a>
    <div fxLayout="column" fxLayoutAlign="center" class="w-100 h-100">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="1rem" class="mt-1">
        <ng-container *ngIf="!authenticatedUser; else signOut">
          <a routerLink="/autentifikimi/identifikim">Identifikohu</a>
          <a routerLink="/autentifikimi/regjistrim">Regjistrohu</a>
        </ng-container>
        <ng-template #signOut>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0.5rem">
            <label>
              <strong>Përshëndetje {{ authenticatedUser.applicantName }} {{ authenticatedUser.applicantSurname }}</strong>
            </label>
            <a (click)="logout()">Dilni</a>
          </div>
        </ng-template>
      </div>
      <div fxFlex></div>
      <ng-container *ngIf="authenticatedUser">
        <div fxLayout="row" fxLayoutAlign="center center">
          <ul class="header-navigation">
            <li routerLinkActive="router-link-active">
              <a routerLink="/shpalljet">
                Kreu
              </a>
            </li>
            <li routerLinkActive="router-link-active">
              <a routerLink="/perdoruesi/aplikimet">
                Aplikimet e mia
              </a>
            </li>
            <li routerLinkActive="router-link-active">
              <a routerLink="/mesazhet/lista">
                Mesazhet e mia ({{messageCount}})
              </a>
            </li>
            <li routerLinkActive="router-link-active">
              <a routerLink="/perdoruesi/profili/redakto">
                Profili im
              </a>
            </li>
            <li routerLinkActive="router-link-active">
              <a routerLink="/testimet">
                Testim
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </header>
  
  <section fxFlex fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex="80" fxFlex.lt-sm="90">
      <router-outlet></router-outlet>
    </div>
  </section>
  
  <footer>
    <label>
      Copyright © {{ currentYear }} Departamenti i Administratës Publike
    </label>
  </footer>
</section>
