import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PositionSelectionService } from 'src/app/services/position-selection.service';
import { AuthService } from 'src/app/services/auth.service';
import { User, AvailablePosition, SelectedPosition } from 'src/app/models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-position-selection',
  templateUrl: './position-selection.component.html',
  styleUrls: ['./position-selection.component.sass']
})
export class PositionSelectionComponent implements OnInit, OnDestroy {
  routeSubscription: Subscription;
  queryParams: any;

  authenticatedUser: User;
  availablePositions: AvailablePosition[] = [];
  selectedPositions: SelectedPosition[] = [];
  selectedPositionId: number;
  deadline: string = '';
  publicationName: string = '';

  isLoading = false;
  isAccessible = false;
  isWaitingResponse = false;
  completed = false;

  displayedColumns = ['nr', 'applicant', 'status', 'selectionDate', 'positionSelected'];

  constructor(
    private positionSelectionService: PositionSelectionService,
    private authService: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.routeSubscription = this._route.queryParams.subscribe((params) => {
      this.queryParams = params;

      if (params && params.param2) {
        this.isLoading = true;
        this.positionSelectionService.getPostions(params.param2)
          .then((res: any) => {
            if (res) {
              this.isAccessible = true;
              this.availablePositions = res.availablePositions;
              this.selectedPositions = res.selectedPositions;
              this.deadline = res.deadline;
              this.publicationName = res.publicationName;
            }
          })
          .finally(() => this.isLoading = false)
          .catch((error) => console.error(error));
      }
    });

    this.authenticatedUser = this.authService.authenticatedUser;
  }

  openConfirmationModal() {
    const dialogRef = this._dialog.open(ConfirmationModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isWaitingResponse = true;

        const payload = {
          id: this.queryParams.param2,
          positionId: this.selectedPositionId
        };

        this.positionSelectionService.selectPostion(payload)
          .then(() =>  {
            this._snackBar.open('Pozicioni u përzgjodh me sukses', null, { duration: 3000 });
            setTimeout(() => {
              this._router.navigate(['/']);
            }, 3000);
          })
          .finally(() => this.isWaitingResponse = false)
          .catch((error) => {
            console.error(error);
            this._snackBar.open('Ndodhi një gabim', null, { duration: 3000 });
          });
      }
    });
  }
}
